import React from "react";
import DashboardLayout from "../../layout/Dashboard.Layout";
import AnimatedGroup from "../../components/AnimatedGroup";
import {
  Anchor,
  Button,
  Container,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { useScrollIntoView } from "@mantine/hooks";

import "@mantine/core/styles/Group.css";
import "@mantine/core/styles/Stack.css";
import "@mantine/core/styles/Container.css";
import "@mantine/core/styles/Paper.css";
import "@mantine/core/styles/Card.css";
import "@mantine/core/styles/Title.css";
import "@mantine/core/styles/Anchor.css";
import "@mantine/core/styles/SimpleGrid.css";

// AI Generation Tools
import AIImageGenerator from "../../static/tools/ai-image-generator.webp";
import PersonlizedAIImage from "../../static/tools/personalized-ai-mage-generator.webp";
import AIHeadshots from "../../static/tools/ai-headshots.webp";

// AI Removal Tools
import RemovedWatermark from "../../static/tools/watermark-removed.webp";
import RemovedBackground from "../../static/tools/background-removed.webp";
import RemovedText from "../../static/tools/text-removed.webp";

// Enhancement Tools
import SharpenPortraits from "../../static/tools/Sharpen-Portraits.webp";
import ColorCorrection from "../../static/tools/Color-Correction.webp";
import UnblurImages from "../../static/tools/Unblur-Images.webp";
import ImageRestoration from "../../static/tools/Image-Restoration.webp";
import UpscaleImage from "../../static/tools/Upscale-Images.webp";
import RetouchImage from "../../static/tools/retouch-image.webp";

const aiGenerationTools = [
  {
    image: AIImageGenerator,
    title: "AI Image Generator",
    description:
      "Generate stunning high-resolution images in seconds with text prompts in just one click. Just type a prompt and let your imagination run wild!",
    link: "/",
    buttonLabel: "Generate Images",
  },

  {
    image: PersonlizedAIImage,
    title: "Personalized AI Image Generator",
    description:
      "Generate personalized AI images. Our AI image generator tool can create unique images based on your preferences.",
    link: "/tools/personalized-image-generator",
    buttonLabel: "Generate Images",
  },
  {
    image: AIHeadshots,
    title: "AI Headshots",
    description:
      "Generate professional & realistic AI headshots from just one photo in seconds with our AI headshots generator tool.",
    link: "/tools/ai-headshot-generator",
    buttonLabel: "Generate Headshots",
  },
];

const aiRemovalTools = [
  {
    image: RemovedText,
    title: "Text Remover",
    description:
      "Quickly and easily remove text from images. Our AI text remover tool can erase text from your photos in seconds without leaving any traces.",
    link: "/tools/text-remover",
    buttonLabel: "Remove Text",
  },
  {
    image: RemovedBackground,
    title: "Background Remover",
    description:
      "Easily remove background from your photos. Our AI background remover tool can get rid of any unwanted background from your images.",
    link: "/tools/background-remover",
    buttonLabel: "Remove Background",
  },
  {
    image: RemovedWatermark,
    title: "Watermark Remover",
    description:
      "Easily remove watermarks from your photos. Our AI watermark remover tool can get rid of any unwanted text or logo from your images.",
    link: "/tools/watermark-remover",
    buttonLabel: "Remove Watermark",
  },
];

const enhancementTools = [
  {
    image: UpscaleImage,
    title: "Upscale Photos",
    description:
      "Upscale your photos with ImgGen's free AI photo upscaler without losing quality or details in seconds with just one click.",
    link: "/tools/upscale-image",
    buttonLabel: "Upscale Photos",
  },
  {
    image: SharpenPortraits,
    title: "Sharpen Portraits Photos",
    description:
      "Instantly sharpen and refine portraits. Our AI sharpens facial details for crisp, clear photos in seconds. Get magazine-quality shots with just one click.",
    link: "/tools/sharpen-portraits",
    buttonLabel: "Sharpen Photos",
  },
  {
    image: ImageRestoration,
    title: "Restore Old Photos",
    description:
      "Revive damaged, faded photos. Our AI restores old images by removing scratches, blur and other defects for free. Bring new life to vintage family photos.",
    link: "/tools/image-restoration",
    buttonLabel: "Restore Old Photo",
  },
  {
    image: ColorCorrection,
    title: "Color Correction",
    description:
      "Easily correct and adjust the colors in your photos. Our AI color correction tool enhances the colors in your images, making them look more vibrant.",
    link: "/tools/color-correction",
    buttonLabel: "Correct Colors",
  },
  {
    image: UnblurImages,
    title: "Unblur Images",
    description:
      "Quickly and easily unblur images. Our AI deblurring tool removes blur and enhances the quality of your photos for free.",
    link: "/tools/unblur-image",
    buttonLabel: "Unblur Photos",
  },
  {
    image: RetouchImage,
    title: "Retouch Photos",
    description:
      "Retouch your photos with our AI photo retouching tool. Adjust brightness, contrast, and more with just one click.",
    link: "/tools/retouch-photo",
    buttonLabel: "Retouch Photos",
  },
];

export function Page() {
  const { scrollIntoView: viewGenerationTools, targetRef: generationToolsRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: 120,
    });

  const { scrollIntoView: viewRemovalTools, targetRef: removalToolsRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: 120,
    });

  const {
    scrollIntoView: viewEnhancementTools,
    targetRef: enhancementToolsRef,
  } = useScrollIntoView<HTMLDivElement>({
    offset: 120,
  });

  return (
    <DashboardLayout>
      <AnimatedGroup>
        <Container w="100%" size="md" pb={0}>
          <Stack justify="center" align="center">
            <Stack align="center">
              <Group justify="center" mt={28}>
                <Button
                  onClick={() => viewGenerationTools({ alignment: "start" })}
                  variant="outline"
                  radius="xl"
                  size="lg"
                  w="max-content"
                >
                  AI Generation Tools
                </Button>
                <Button
                  onClick={() => viewRemovalTools({ alignment: "start" })}
                  variant="outline"
                  radius="xl"
                  size="lg"
                  w="max-content"
                >
                  AI Removal Tools
                </Button>
                <Button
                  onClick={() => viewEnhancementTools({ alignment: "start" })}
                  variant="outline"
                  radius="xl"
                  size="lg"
                  w="max-content"
                >
                  AI Enhancement Tools
                </Button>
              </Group>
            </Stack>
          </Stack>
        </Container>
      </AnimatedGroup>

      <AnimatedGroup>
        <Container w="100%" size="xl" pt={90} pb={30}>
          <Stack gap={70} mb={60}>
            <Stack ref={generationToolsRef} justify="center" align="center">
              <Title ta="center" c="#FFF" size="h2" order={2} tt="capitalize">
                AI Generation Tools
              </Title>

              <SimpleGrid
                cols={{
                  base: 1,
                  sm: 2,
                  md: 3,
                }}
                spacing={{
                  base: 20,
                  sm: 50,
                }}
                mt={40}
              >
                {aiGenerationTools.map((tool, index) => (
                  <Stack mb={20} key={index}>
                    <Image src={tool.image} w="auto" alt={tool.title} />

                    <Anchor href={tool.link}>
                      <Title order={3}>{tool.title}</Title>
                    </Anchor>
                    <Text>{tool.description}</Text>

                    <Button
                      mt={6}
                      component="a"
                      w="max-content"
                      variant="light"
                      rightSection={<IconArrowNarrowRight />}
                      href={tool.link}
                    >
                      {tool.buttonLabel}
                    </Button>
                  </Stack>
                ))}
              </SimpleGrid>
            </Stack>

            <Stack ref={removalToolsRef} justify="center" align="center">
              <Title ta="center" c="#FFF" size="h2" order={2} tt="capitalize">
                AI Removal Tools
              </Title>

              <SimpleGrid
                cols={{
                  base: 1,
                  sm: 2,
                  md: 3,
                }}
                spacing={{
                  base: 20,
                  sm: 50,
                }}
                mt={40}
              >
                {aiRemovalTools.map((tool, index) => (
                  <Stack mb={20} key={index}>
                    <Image src={tool.image} w="auto" alt={tool.title} />

                    <Anchor href={tool.link}>
                      <Title order={3}>{tool.title}</Title>
                    </Anchor>
                    <Text>{tool.description}</Text>

                    <Button
                      mt={6}
                      component="a"
                      w="max-content"
                      variant="light"
                      rightSection={<IconArrowNarrowRight />}
                      href={tool.link}
                    >
                      {tool.buttonLabel}
                    </Button>
                  </Stack>
                ))}
              </SimpleGrid>
            </Stack>

            <Stack ref={enhancementToolsRef} justify="center" align="center">
              <Title ta="center" c="#FFF" size="h2" order={2} tt="capitalize">
                AI Enhancement Tools
              </Title>

              <SimpleGrid
                cols={{
                  base: 1,
                  sm: 2,
                  md: 3,
                }}
                spacing={{
                  base: 20,
                  sm: 50,
                }}
                mt={40}
              >
                {enhancementTools.map((tool, index) => (
                  <Stack mb={20} key={index}>
                    <Image src={tool.image} w="auto" alt={tool.title} />

                    <Anchor href={tool.link}>
                      <Title order={3}>{tool.title}</Title>
                    </Anchor>
                    <Text>{tool.description}</Text>

                    <Button
                      mt={6}
                      component="a"
                      w="max-content"
                      variant="light"
                      rightSection={<IconArrowNarrowRight />}
                      href={tool.link}
                    >
                      {tool.buttonLabel}
                    </Button>
                  </Stack>
                ))}
              </SimpleGrid>
            </Stack>
          </Stack>
        </Container>
      </AnimatedGroup>
    </DashboardLayout>
  );
}
